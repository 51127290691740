<template lang="pug">
#connect-to-agent(v-esc-close="cancel")
  #close-button
    icon#close-icon(data="@icon/close.svg", @click="cancel")
  .modal-header
    h2 {{ modalTitle }}

  .modal-text
    p {{ nudgeText }} Connect with a live agent to help guide you through the Kit process.

    #support-hours(v-if="allowCancel")
      #header
        h2 Support hours:
      #body
        icon#icon(data="@icon/clock-icon.svg")
        p {{ toLocalHour(10) }}:00am - {{ toLocalHour(1) }}:00pm {{ localTimeZone }} Monday-Friday <br> {{ toLocalHour(12) }}:00am - {{ toLocalHour(22) }}:00pm {{ localTimeZone }} Saturday-Sunday
      #body
        icon#icon(data="@icon/calendar.svg")
        p Closed on Thanksgiving, Christmas Day, New Years Day

  .modal-footer
    button.button.action-btn(@click="connectToAgent") Start Video Call
    button.button(v-if="allowCancel", @click="cancel") Cancel
</template>

<script>
import Vue from 'vue'
import AmplitudeAPI from '@/utils/amplitude'
import MixpanelAPI from '@/utils/mixpanel'
import { mapState, mapGetters } from 'vuex'

export default {
  props: {
    allowCancel: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: { type: String },
    prompt: { type: String },
  },

  data() {
    return {
      stepTimeStamp: Date.now(),
      localTimeZone: null,
    }
  },

  computed: {
    ...mapState({
      localVideoTrack: state => state.twilio.localVideoTrack,
      localAudioTrack: state => state.twilio.localAudioTrack,
      proceededWithoutVideo: state => state.user.proceededWithoutVideo,
    }),
    ...mapGetters({
      proceededWithoutVideo: 'user/getVideoPermission',
    }),
    modalTitle,
    nudgeText,
  },

  watch: {},

  methods: {
    connectToAgent,
    cancel,
    toLocalHour,
  },

  components: {},

  mounted() {
    var date = new Date()
    this.localTimeZone = date.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
  },
}

/* Computed ---------------------------------------------------- */
function modalTitle() {
  return this.allowCancel ? 'Need Help?' : "Let's Get You Some Help"
}

function nudgeText() {
  return this.allowCancel ? null : 'It looks like you may need assistance.'
}

/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function toLocalHour(hour) {
  var utcTime = new Date(`1/1/2099 ${hour}:00:00 UTC`)
  return (utcTime.getHours() + 24) % 12 || 12
}
async function connectToAgent() {
  // send help request to CC
  this.$app.showCallConnecting = true
  this.$app.callTimestamp = Date.now()
  this.$app.callDate = new Date()

  // send email alert
  const orderId = this.$store.getters['user/getMedplumOrderId']
  if (orderId) {
    Vue.axios
      .post('/customer-help', {
        orderId: orderId,
      })
      .catch(error => {
        console.error('Error in sending mail notification: ', error.message)
        this.$bugsnag.notify(error)
      })
  }

  const eventTitle = 'HelpRequested'
  const eventProperties = {
    segment: this.title,
    step: this.prompt,
    kit_type: this.$store.getters['user/getKitType'],
    sku: this.$store.getters['user/getSku'],
    medplum_id: this.$store.getters['user/getMedplumOrderId'],
    barcode: this.$store.getters['user/getBarcodeId'],
    customer: this.$store.getters['user/getConfigKeys']
      ? this.$store.getters['user/getConfigKeys'].carrier
      : null,
    segment_time: Date.now() - this.stepTimeStamp,
    source: this.$route.query.src,
  }

  AmplitudeAPI.logEvent(eventTitle, {
    ...eventProperties,
    linked_amplitude_id: this.$route.query.q,
  })

  MixpanelAPI.track(eventTitle, {
    ...eventProperties,
    application: 'Collection App',
  })
}

function cancel() {
  this.$modal.close('ConnectToAgent')
}
</script>
